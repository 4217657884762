<template>
  <div>
    <el-dialog title="额度充值" :visible.sync="payShow" width="40%" center :append-to-body="true">
      <el-form label-width="100px" :model="payFrom" class="opts_line">
        <el-form-item label="充值额度：" required>
          <div class="paySelect">
            <div class="norBox" :class="payType==1&&thisNum==item?'hovBox':''" @click="onGoodsPay(1,item)" v-for="(item,index) in option_list" :key="index">
              {{ item }}个商品</div>
            <div class="norBox" :class="payType==2?'hovBox':''" @click="onGoodsPay(2)">其他
              <el-input placeholder="请输入数量" :min="min_num" v-model="otherNum" :step="0" type="Number" style="width: 110px"
                        size="mini" @blur="inpNum"></el-input>
              个
            </div>
          </div>
        </el-form-item>
        <el-form-item label="价格：" required>
          <div style="color: red;font-weight: bold;font-size: 20px">￥{{ price }}</div>
        </el-form-item>
        <el-form-item label="充值方式：" required>
          <div class="norBox hovBox" style="background-color: rgb(2,167,240);color:#ffffff">支付宝</div>
        </el-form-item>
        <el-form-item label=""  required>
          <el-button type="primary" @click="onSubmit" :loading="loading">立即充值</el-button>
        </el-form-item>
        <div style="font-weight: bold;font-size: 16px">温馨提示</div>
        <div>您的店长将于{{ info.app_expire_time_text }}过期，充值额度只用于临时增加软件有效期内的搬家额度，<span style="color: rgb(245,158,43)">额度用完即止，
          且不会延长使用时间</span>。如需延长使用时间，请前往 <a href="https://fxg.jinritemai.com/ffa/mopen/application" target="_blank">服务市场订购</a>。</div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapActions, mapState, mapMutations} from "vuex";

export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      loading: false,
      payShow: false,
      payFrom: {},
      payType: 1,
      thisNum:'',
      otherNum: '',
      info:{},
      option_list:[],
      unit_price:'',
      min_num:0,
      price:'',
      trade_no:''
    };
  },

  created() {
  },
  mounted() {
  },
  methods: {
    ...mapActions({
      opOrderManageBatAbnormal: "ucenter/opOrderManageBatAbnormal",
      packageList: "ucenter/getPackageList",
      packageRecharge: "ucenter/setpackageRecharge",
      packageCheckOrder: "ucenter/getpackageCheckOrder",
    }),
    show(row) {
      this.info = row
      this.payType = 1
      this.otherNum = ''
      this.price = ''
      this.getList()
      this.payShow = true
    },
    onGoodsPay(type,item) {
      this.payType = type
      if(type==1){
        this.thisNum = item
      }
      this.getPrice()
    },
    inpNum(e){
      // console.log(e.target.value)
      let val = e.target.value
      if(val < this.min_num){
        this.$message.warning('最小充值数量为'+this.min_num+'个');
        this.otherNum = this.min_num
      }
      this.getPrice()
    },
    onSubmit() {
      if (this.loading) {
        return
      }
      // let http = window.location.protocol;
      // let host = window.location.host;
      let returnUrl = window.location.href
      let num = this.thisNum
      if(this.payType==2){
        num = this.otherNum
        if(!num || num < this.min_num){
          this.$message.warning('请输入充值数量');
          return
        }
      }
      this.loading = true
      let param = {shop_id: this.info.shop_id, return_url:returnUrl,num:num}
      this.packageRecharge({
        data: param, success: (res) => {
          this.loading = false
          if (res.code == 200) {
            this.trade_no = res.data.trade_no
            this.$confirm('支付中...', '提示', {
              confirmButtonText: '支付完成',
              cancelButtonText: '遇到问题',
              type: 'warning',
              center: true
            }).then(() => {
              console.log('支付完成')
              this.getCheckOrder()
            }).catch(() => {
              console.log('遇到问题')
              // this.$router.replace('/')
            });
            let divForm = document.getElementsByTagName('divform')
            if (divForm.length) {
              document.body.removeChild(divForm[0])
            }
            const div = document.createElement('divform');
            div.innerHTML = res.data.pay_data;
            document.body.appendChild(div);
            document.forms['alipaysubmit'].setAttribute('target', '_blank')
            document.forms['alipaysubmit'].submit();
          } else {
            this.$message({message: res.msg, type: 'error'})
          }
        }
      })
    },

    getList() {
      let param = {}
      this.packageList({
        data: param, success: (res) => {
          if (res.code == 200) {
            this.option_list = res.data.option_list
            this.unit_price = Number(res.data.unit_price)
            this.min_num = Number(res.data.min_num)
            if(res.data.option_list.length){
              this.thisNum = res.data.option_list[0]
              this.getPrice()
            }
          } else {
            this.$message({message: res.msg, type: 'error'})
          }
        }
      })
    },
    getCheckOrder() {
      let param = { trade_no:this.trade_no}
      this.packageCheckOrder({
        data: param, success: (res) => {
          if (res.code == 200) {
            this.$message.success(res.msg)
            this.payShow = false
            this.$emit('success')
            // window.location.reload()
          } else {
            this.$message({message: res.msg, type: 'error'})
          }
        }
      })
    },
    getPrice(){
      if(this.payType==1){
        this.price = Number(this.unit_price*this.thisNum).toFixed(2)
      }else{
        if(this.otherNum >= this.min_num){
          this.price = Number(this.unit_price*this.otherNum).toFixed(2)
        }else{
          this.price = 0
        }
      }
    },
  },

}
</script>

<style scoped>

.paySelect {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.norBox {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 2px 12px;
  margin-right: 10px;
  cursor: pointer;
  width: fit-content;
  margin-bottom: 10px;
}

.hovBox {
  background: rgb(196, 237, 255);
  border: 1px solid rgb(196, 237, 255);
}
</style>